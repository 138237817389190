import { Box, Button, Input, Select, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import CustomTable from "../../components/UI/Table";
import {
  betHistoryAPI,
  getBetHistoryAPI,
  unsettledBetsAPI,
} from "../../service/AuthService";
import { getCurrentDateTimeLocal } from "../../utils/constants";
import moment from "moment/moment";
import useSound from "use-sound";
import ping from "../../assets/sound/ping.mp3";
import { searchChildrenAPI } from "../../service/UserService";
import AsyncSelect from "react-select/async";
export default function CurrentBets() {
  const [from, setFrom] = React.useState(getCurrentDateTimeLocal(true));
  const [to, setTo] = React.useState(getCurrentDateTimeLocal());
  const [sport, setSport] = React.useState("CRICKET");
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [search_, setSearch] = React.useState("");
  const [betStatus, setBetStatus] = React.useState("unsettle");
  const [user, setUser] = React.useState("");
  const [play] = useSound(ping);
  const getBetHistory = async (userName) => {
    let payload = {
      betStatus: betStatus?.toUpperCase(),
      pageNumber: page,
      pageSize: size,
    };
    if (betStatus === "unsettle") {
      if (search_.length > 0) {
        payload["search"] = userName;
      }
      payload["betStatus"] = "MATCHED";
      const {
        response: { content },
      } = await unsettledBetsAPI(payload);
      let data_ = content.map((item, index) => ({
        ...item,
        bet_type: item.back_rate || item.back_run ? "Back" : "Lay",
        rate: item.back_run ? item.back_run : item.lay_run,
        "p/l": item.profit ? item.profit : item.loss,
      }));
      let data__ = JSON.parse(localStorage.getItem("betHistory"));
      if (localStorage.getItem("betHistory")) {
        if (content[0]?.created_date != data__[0]?.created_date) {
          sendNotification(
            "New Bet Placed",
            `Bet Placed on ${content[0]?.event_name} by ${content[0]?.userName}`
          );
          play();
        }
      }
      localStorage.setItem("betHistory", JSON.stringify(data_));
      setData(data_);
    } else {
      if (!user) return;
      payload["from"] = moment(from).toISOString();
      payload["to"] = moment(to).toISOString();
      payload["page"] = page;
      payload["size"] = size;
      payload["sport"] = sport;
      payload["userName"] = user?.value;
      payload["matched"] = true;
      const { response } = await getBetHistoryAPI(payload);
      let data_ = response?.content.map((item, index) => ({
        ...item,
        bet_type: item.back_rate || item.back_run ? "Back" : "Lay",
        rate: item.back_run ? item.back_run : item.lay_run,
        "p/l": item.profit ? item.profit : item.loss,
      }));
      
      
      setData(data_);
    }
  };

  const search = (e) => {
    let payload = data;
    let inputValue = e?.target?.value?.trim()?.toLowerCase();
    setSearch(inputValue);
    if (e?.target?.value?.trim()?.length > 0) {
      payload = data.filter(
        (item) =>
          item?.bet_type?.toLowerCase()?.search(inputValue) != -1 ||
          item?.event_name?.toLowerCase()?.search(inputValue) != -1 ||
          item?.runner_name?.toLowerCase()?.search(inputValue) != -1 ||
          item?.userName?.toLowerCase()?.search(inputValue) != -1 ||
          item?.marketName?.toLowerCase()?.search(inputValue) != -1 ||
          String(item?.amount)?.toLowerCase()?.search(inputValue) != -1 ||
          String(item?.back_rate)?.toLowerCase()?.search(inputValue) != -1 ||
          String(item?.lay_rate)?.toLowerCase()?.search(inputValue) != -1 ||
          item?.event_type?.toLowerCase()?.search(inputValue) != -1
      );
      setData(payload);
    } else {
      getBetHistory(e.target.value);
      setSize(10);
    }
  };
  const nextPage = () => {
    if (data.length > 0) {
      setPage(page + 1);
    }
  };
  const prevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };
  const sendNotification = (title, body) => {
    play();
    if (Notification.permission === "granted") {
      new Notification(title, { body });
    }
  };
  const getUserName = async (user) => {
    const { response } = await searchChildrenAPI(user);
  
      
    
    return response.childrenUserResponseDTOS.map((item) => ({
      value: item.username,
      label: item.username,
    }))
  };
  const enableNotification = () => {
    console.log("enableNotification");
    Notification.requestPermission();
  };
  useEffect(() => {
    getBetHistory();
    enableNotification();
  }, [page, size]);
  return (
    <app-bet-list _nghost-ng-c2822817509="" className="ng-star-inserted">
      <div _ngcontent-ng-c2822817509="" className="animated fadeIn divider-top">
        <div _ngcontent-ng-c2822817509="" className="row">
          <div _ngcontent-ng-c2822817509="" className="col-sm-12 col-md-12">
            <div _ngcontent-ng-c2822817509="" className="card card-report">
              <div _ngcontent-ng-c2822817509="" className="card-body row p-0">
                {/**/}
                <div
                  _ngcontent-ng-c2822817509=""
                  className="col-lg-2 col-md-4 col-12"
                >
                  <div
                    _ngcontent-ng-c2822817509=""
                    className="form-group InputFields"
                  >
                    <div
                      _ngcontent-ng-c2822817509=""
                      htmlFor="startDate"
                      className="mb-2 labmarg"
                    >
                      Choose Type
                    </div>
                    <div _ngcontent-ng-c2822817509="" className="input-group">
                      <select
                        _ngcontent-ng-c2822817509=""
                        name="type"
                        value={betStatus}
                        onChange={(e) => setBetStatus(e.target.value)}
                        className="form-control form-select ng-pristine ng-valid ng-touched"
                      >
                        <option _ngcontent-ng-c2822817509="" value="settle">
                          Settle
                        </option>
                        <option _ngcontent-ng-c2822817509="" value="unsettle">
                          UnSettle
                        </option>
                        <option _ngcontent-ng-c2822817509="" value="void">
                          Void
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                {betStatus == "settle" && (
                  <div
                    _ngcontent-ng-c2822817509=""
                    className="col-lg-2 col-md-4 col-12"
                  >
                    <div
                      _ngcontent-ng-c2822817509=""
                      className="form-group InputFields"
                    >
                      <div
                        _ngcontent-ng-c2822817509=""
                        htmlFor="startDate"
                        className="mb-2"
                      >
                        Choose User
                      </div>
                      <div _ngcontent-ng-c2822817509="" className="input-group">
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          loadOptions={getUserName}
                          onChange={(e) => setUser(e)}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div
                  _ngcontent-ng-c2822817509=""
                  className="col-lg-2 col-md-4 col-12"
                >
                  <div
                    _ngcontent-ng-c2822817509=""
                    className="form-group InputFields"
                  >
                    <div
                      _ngcontent-ng-c2822817509=""
                      htmlFor="startDate"
                      className="mb-2"
                    >
                      Choose Sport
                    </div>
                    <div _ngcontent-ng-c2822817509="" className="input-group">
                      <select
                        _ngcontent-ng-c2822817509=""
                        name="type"
                        value={sport}
                        className="sel form-control form-select ng-untouched ng-pristine ng-valid"
                      >
                        <option
                          _ngcontent-ng-c2822817509=""
                          value=""
                          disabled=""
                        >
                          Select Sport
                        </option>
                        <option
                          _ngcontent-ng-c2822817509=""
                          value={"CRICKET"}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Cricket{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2822817509=""
                          value={"TENNIS"}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Tennis{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2822817509=""
                          value={"CASINO"}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Casino{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2822817509=""
                          value={"SOCCER"}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Soccer{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2822817509=""
                          value={7}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Horse Racing{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2822817509=""
                          value={4339}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Greyhound Racing{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2822817509=""
                          value={27979456}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Kabaddi{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2822817509=""
                          value={7522}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Basketball{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2822817509=""
                          value={2378961}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Politics{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2822817509=""
                          value={66101}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Virtual Sports{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2822817509=""
                          value={66103}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Binary{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2822817509=""
                          value={66104}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Lottery{" "}
                        </option>
                        {/**/}
                      </select>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c2822817509=""
                  className="col-lg-2 col-md-4 col-12"
                >
                  <div
                    _ngcontent-ng-c2822817509=""
                    className="form-group InputFields"
                  >
                    <div
                      _ngcontent-ng-c2822817509=""
                      htmlFor="startDate"
                      className="mb-2"
                    >
                      From
                    </div>
                    <div _ngcontent-ng-c2822817509="" className="input-group">
                      <input
                        _ngcontent-ng-c2822817509=""
                        placeholder="Select Date"
                        name="mydate"
                        readOnly=""
                        value={moment(from).format("YYYY-MM-DD")}
                        type="date"
                        autoComplete="off"
                        onChange={(e) => setFrom(e.target.value)}
                        className="form-control dateClass ng-untouched ng-pristine ng-valid"
                        style={{ padding: "0px !important" }}
                      ></input>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c2822817509=""
                  className="col-lg-2 col-md-4 col-12"
                >
                  <div
                    _ngcontent-ng-c2822817509=""
                    className="form-group InputFields"
                  >
                    <div
                      _ngcontent-ng-c2822817509=""
                      htmlFor="endDate"
                      className="mb-2"
                    >
                      To
                    </div>
                    <input
                      _ngcontent-ng-c2822817509=""
                      placeholder="Select Date"
                      name="mydate"
                      readOnly=""
                      value={moment(to).format("YYYY-MM-DD")}
                      type="date"
                      autoComplete="off"
                      onChange={(e) => setTo(e.target.value)}
                      className="form-control dateClass ng-untouched ng-pristine ng-valid"
                      style={{ padding: "0px !important" }}
                    ></input>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c2822817509=""
                  className="col-lg-2 col-md-4 col-12"
                >
                  <div _ngcontent-ng-c2822817509="" className="form-group">
                    <div
                      _ngcontent-ng-c2822817509=""
                      htmlFor="type"
                      className="mobile-d-none"
                      style={{ width: "100%" }}
                    >
                      &nbsp;
                    </div>
                    <button
                      _ngcontent-ng-c2822817509=""
                      type="button"
                      onClick={() => getBetHistory(search_)}
                      className="btn btn-primary"
                    >
                      <strong _ngcontent-ng-c2822817509="">Get History</strong>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div _ngcontent-ng-c2822817509="" className="row">
          <div _ngcontent-ng-c2822817509="" className="col-sm-12 col-md-12">
            <div
              _ngcontent-ng-c2822817509=""
              className="card card-accent-primary"
            >
              <div
                _ngcontent-ng-c2822817509=""
                className="card-header account-detail-head"
              >
                {" "}
                Bet History{" "}
                <a
                  _ngcontent-ng-c2822817509=""
                  placement="right"
                  popover="Betting History is available online for the past 30 days."
                  triggers="click"
                >
                  <i
                    _ngcontent-ng-c2822817509=""
                    className="fa fa-question-circle"
                  />
                </a>
              </div>
              <div
                _ngcontent-ng-c2822817509=""
                className="card-body account-stat-body"
              >
                <div
                  _ngcontent-ng-c2822817509=""
                  className="table-responsive table table-striped"
                >
                  <div
                    id="DataTables_Table_37_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      className="dataTables_length"
                      id="DataTables_Table_37_length"
                    >
                      <label>
                        Show{" "}
                        <select
                          name="DataTables_Table_37_length"
                          aria-controls="DataTables_Table_37"
                          className=""
                          value={size}
                          onChange={(e) => setSize(e.target.value)}
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>{" "}
                        entries
                      </label>
                    </div>
                    <div
                      id="DataTables_Table_37_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        Search:
                        <input
                          type="search"
                          className=""
                          placeholder=""
                          value={search_}
                          onChange={(e) => search(e)}
                          aria-controls="DataTables_Table_37"
                        />
                      </label>
                    </div>
                    <table
                      _ngcontent-ng-c2822817509=""
                      datatable=""
                      role="table"
                      aria-busy="false"
                      aria-colcount={6}
                      className="table table-bordered table-striped dataTable no-footer"
                      id="DataTables_Table_37"
                      aria-describedby="DataTables_Table_37_info"
                    >
                      <thead _ngcontent-ng-c2822817509="" role="rowgroup">
                        <tr _ngcontent-ng-c2822817509="" role="row">
                          <th
                            _ngcontent-ng-c2822817509=""
                            className="sorting sorting_desc"
                            tabIndex={0}
                            aria-controls="DataTables_Table_37"
                            rowSpan={1}
                            colSpan={1}
                            aria-sort="descending"
                            aria-label="User Name: activate to sort column ascending"
                          >
                            User Name
                          </th>
                          <th
                            _ngcontent-ng-c2822817509=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_37"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="SportName: activate to sort column ascending"
                          >
                            SportName
                          </th>
                          <th
                            _ngcontent-ng-c2822817509=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_37"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Event: activate to sort column ascending"
                          >
                            Event
                          </th>
                          <th
                            _ngcontent-ng-c2822817509=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_37"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Market: activate to sort column ascending"
                          >
                            Market
                          </th>
                          <th
                            _ngcontent-ng-c2822817509=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_37"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Selection: activate to sort column ascending"
                          >
                            Selection
                          </th>
                          <th
                            _ngcontent-ng-c2822817509=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_37"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Type: activate to sort column ascending"
                          >
                            Type
                          </th>
                          <th
                            _ngcontent-ng-c2822817509=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_37"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Odds Req.: activate to sort column ascending"
                          >
                            Odds Req.
                          </th>
                          <th
                            _ngcontent-ng-c2822817509=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_37"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Stack: activate to sort column ascending"
                          >
                            Stack
                          </th>
                          <th
                            _ngcontent-ng-c2822817509=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_37"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Place Time: activate to sort column ascending"
                          >
                            Place Time
                          </th>
                          <th
                            _ngcontent-ng-c2822817509=""
                            rowSpan={1}
                            colSpan={1}
                          >
                            Settle Time
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, index) => (
                          <tr
                            _ngcontent-ng-c2822817509=""
                            role="row"
                            className={`${index % 2 === 0 ? "even" : "odd"} ${
                              item.back_rate || item?.back_run ? "back" : "lay"
                            }`}
                          >
                            <td
                              _ngcontent-ng-c2822817509=""
                              className="text-center"
                            >
                              {item.userName}
                            </td>
                            <td
                              _ngcontent-ng-c2822817509=""
                              className="text-center"
                            >
                              {item.event_type}
                            </td>
                            <td
                              _ngcontent-ng-c2822817509=""
                              className="text-center"
                            >
                              {item.event_name}
                            </td>
                            <td
                              _ngcontent-ng-c2822817509=""
                              className="text-center"
                            >
                              {item.marketName}
                            </td>
                            <td
                              _ngcontent-ng-c2822817509=""
                              className="text-center"
                            >
                              {item.runner_name}
                            </td>
                            <td
                              _ngcontent-ng-c2822817509=""
                              className="text-center"
                            >
                              {item.bet_type}
                            </td>
                            <td
                              _ngcontent-ng-c2822817509=""
                              className="text-center"
                            >
                              {item.rate}
                            </td>
                            <td
                              _ngcontent-ng-c2822817509=""
                              className="text-center"
                            >
                              {item.amount}
                            </td>
                            <td
                              _ngcontent-ng-c2822817509=""
                              className="text-center"
                            >
                              {moment(item.created_date + "Z").format(
                                "YYYY-MM-DD HH:mm:ss"
                              )}
                            </td>
                            <td
                              _ngcontent-ng-c2822817509=""
                              className="text-center"
                            >
                              {moment(item.created_date + "Z").format(
                                "YYYY-MM-DD HH:mm:ss"
                              )}
                            </td>
                          </tr>
                        ))}
                        {data.length === 0 && (
                          <tr className="odd">
                            <td
                              valign="top"
                              colSpan={9}
                              className="dataTables_empty"
                            >
                              No data available in table
                            </td>
                          </tr>
                        )}
                      </tbody>
                      {/**/}
                    </table>
                    <div
                      className="dataTables_info"
                      id="DataTables_Table_37_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing {page * size + 1} to {page * size + data.length}{" "}
                      of {data.length} entries
                    </div>
                    <div
                      className="dataTables_paginate paging_full_numbers"
                      id="DataTables_Table_37_paginate"
                    >
                      <a
                        className="paginate_button first disabled"
                        aria-controls="DataTables_Table_37"
                        aria-disabled="true"
                        role="link"
                        data-dt-idx="first"
                        onClick={() => setPage(0)}
                        tabIndex={-1}
                        id="DataTables_Table_37_first"
                      >
                        First
                      </a>
                      <a
                        className="paginate_button previous disabled"
                        aria-controls="DataTables_Table_37"
                        aria-disabled="true"
                        role="link"
                        data-dt-idx="previous"
                        onClick={prevPage}
                        tabIndex={-1}
                        id="DataTables_Table_37_previous"
                      >
                        Previous
                      </a>
                      <span />
                      <a
                        className="paginate_button next disabled"
                        aria-controls="DataTables_Table_37"
                        aria-disabled="true"
                        role="link"
                        data-dt-idx="next"
                        onClick={nextPage}
                        tabIndex={-1}
                        id="DataTables_Table_37_next"
                      >
                        Next
                      </a>
                      <a
                        className="paginate_button last disabled"
                        aria-controls="DataTables_Table_37"
                        aria-disabled="true"
                        role="link"
                        data-dt-idx="last"
                        onClick={() => setPage(Math.floor(data.length / size))}
                        tabIndex={-1}
                        id="DataTables_Table_37_last"
                      >
                        Last
                      </a>
                    </div>
                  </div>
                  {/**/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        _ngcontent-ng-c2822817509=""
        id="modalparentList"
        tabIndex={-1}
        aria-labelledby="yourModalLabel"
        aria-hidden="true"
        className="modal fade"
      >
        <div _ngcontent-ng-c2822817509="" className="modal-dialog modal-sm">
          <div _ngcontent-ng-c2822817509="" className="modal-content">
            <div
              _ngcontent-ng-c2822817509=""
              className="modal-header bg-primary"
            >
              <h4
                _ngcontent-ng-c2822817509=""
                id="modal-basic-title"
                className="modal-title"
              >
                Parent List
              </h4>
              <button
                _ngcontent-ng-c2822817509=""
                type="button"
                aria-label="Close"
                data-bs-dismiss="modal"
                className="close"
              >
                <span _ngcontent-ng-c2822817509="" aria-hidden="true">
                  ×
                </span>
              </button>
            </div>
            <div
              _ngcontent-ng-c2822817509=""
              className="modal-body text-center"
            >
              <ul _ngcontent-ng-c2822817509="" className="list-group">
                {/**/}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </app-bet-list>
  );
}
