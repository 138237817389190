import moment from "moment";
import React from "react";
import { useParams } from "react-router-dom";

export default function BetList({ data }) {
  const { sport, userId } = useParams();
  return (
    <app-user-bet-history
      _nghost-ng-c2363829533=""
      className="ng-star-inserted"
    >
      <div _ngcontent-ng-c2363829533="" className="detail-row">
        <div
          _ngcontent-ng-c2363829533=""
          className="d-flex justify-content-end w-100 mb-2"
        >
          <div _ngcontent-ng-c2363829533="" className="back indicators ms-1">
            {" "}
            Back{" "}
          </div>
          <div _ngcontent-ng-c2363829533="" className="lay indicators ms-1">
            {" "}
            Lay{" "}
          </div>
          <div _ngcontent-ng-c2363829533="" className="voidBet indicators ms-1">
            {" "}
            Void{" "}
          </div>
        </div>
        <div _ngcontent-ng-c2363829533="" className="card card-accent-primary">
          <div _ngcontent-ng-c2363829533="" className="game-heading">
            <span _ngcontent-ng-c2363829533="" className="card-header-title">
              Bet History
            </span>
          </div>
          <div
            _ngcontent-ng-c2363829533=""
            className="card-body account-detail-body p-0"
            style={{ overflow: "auto" }}
          >
            <table
              _ngcontent-ng-c2363829533=""
              id="example"
              className="table-responsive table table-striped table-bordered"
              style={{ width: "100%" }}
            >
              <thead _ngcontent-ng-c2363829533="">
                <tr _ngcontent-ng-c2363829533="" role="row">
                  <th
                    _ngcontent-ng-c2363829533=""
                    role="columnheader"
                    scope="col"
                    aria-colindex={1}
                    className="text-center"
                  >
                    Sport Name
                  </th>
                  <th
                    _ngcontent-ng-c2363829533=""
                    role="columnheader"
                    scope="col"
                    aria-colindex={2}
                    className="text-center"
                  >
                    Event Name{" "}
                  </th>
                  <th
                    _ngcontent-ng-c2363829533=""
                    role="columnheader"
                    scope="col"
                    aria-colindex={3}
                    className="text-center min-width-100"
                  >
                    Market Name{" "}
                  </th>
                  <th
                    _ngcontent-ng-c2363829533=""
                    role="columnheader"
                    scope="col"
                    aria-colindex={4}
                    className="text-center"
                  >
                    Runner Name{" "}
                  </th>
                  <th
                    _ngcontent-ng-c2363829533=""
                    role="columnheader"
                    scope="col"
                    aria-colindex={5}
                    className="text-center"
                  >
                    Bet Type
                  </th>
                  <th
                    _ngcontent-ng-c2363829533=""
                    role="columnheader"
                    scope="col"
                    aria-colindex={6}
                    className="text-center"
                  >
                    User Price{" "}
                  </th>
                  <th
                    _ngcontent-ng-c2363829533=""
                    role="columnheader"
                    scope="col"
                    aria-colindex={7}
                    className="text-center"
                  >
                    Amount{" "}
                  </th>
                  <th
                    _ngcontent-ng-c2363829533=""
                    role="columnheader"
                    scope="col"
                    aria-colindex={8}
                    className="text-center"
                  >
                    PL{" "}
                  </th>
                  <th
                    _ngcontent-ng-c2363829533=""
                    role="columnheader"
                    scope="col"
                    aria-colindex={9}
                    className="text-center min-width-100"
                  >
                    Place Date{" "}
                  </th>
                  <th
                    _ngcontent-ng-c2363829533=""
                    role="columnheader"
                    scope="col"
                    aria-colindex={10}
                    className="text-center min-width-100"
                  >
                    Match Date
                  </th>
                  <th
                    _ngcontent-ng-c2363829533=""
                    role="columnheader"
                    scope="col"
                    aria-colindex={10}
                    className="text-center"
                  >
                    Details
                  </th>
                </tr>
              </thead>
              <tbody _ngcontent-ng-c2363829533="">
                {data?.map((item) => (
                  <tr
                    _ngcontent-ng-c2363829533=""
                    className={`${
                      item?.lay_rate || item?.lay_run ? "lay" : "back"
                    } ng-star-inserted`}
                  >
                    <td _ngcontent-ng-c2363829533="" className="text-center">
                      {sport}
                    </td>
                    <td _ngcontent-ng-c2363829533="" className="text-center">
                      {item.event_name}
                    </td>
                    <td _ngcontent-ng-c2363829533="" className="text-center">
                      {item?.marketName ? item?.marketName : item.runner_name}
                    </td>
                    <td _ngcontent-ng-c2363829533="" className="text-center">
                      {item.runner_name}
                    </td>
                    <td _ngcontent-ng-c2363829533="" className="text-center">
                      {item?.lay_rate || item?.lay_run ? "Lay" : "Back"}
                    </td>
                    <td _ngcontent-ng-c2363829533="" className="text-center">
                      {item?.lay_rate || item?.lay_run
                        ? item?.lay_run
                        : item?.back_run}
                      /
                      {item?.lay_rate || item?.lay_run
                        ? item?.lay_rate
                        : item?.back_rate}
                    </td>
                    <td _ngcontent-ng-c2363829533="" className="text-center">
                      {item.amount}
                    </td>
                    <td _ngcontent-ng-c2363829533="" className="text-center">
                      <span
                        _ngcontent-ng-c2363829533=""
                        className={item?.profit ? "cl-green" : "cl-red"}
                      >
                        {" "}
                        {item?.profit ? item?.profit : -item?.loss}{" "}
                      </span>
                    </td>
                    <td _ngcontent-ng-c2363829533="" className="text-center">
                      {moment(item.created_date + "Z").format(
                        "MMM DD, YYYY, h:mm:ss A"
                      )}
                    </td>
                    <td _ngcontent-ng-c2363829533="" className="text-center">
                      {moment(item.created_date + "Z").format(
                        "MMM DD, YYYY, h:mm:ss A"
                      )}
                    </td>
                    <td _ngcontent-ng-c2363829533="" className="text-center">
                      <a
                        _ngcontent-ng-c2363829533=""
                        data-bs-toggle="modal"
                        data-bs-target="#BetDetailsModal"
                        className="detailsLink"
                      >
                        Info
                      </a>
                    </td>
                  </tr>
                ))}
                {/**/}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        _ngcontent-ng-c2363829533=""
        id="BetDetailsModal"
        tabIndex={-1}
        aria-labelledby="yourModalLabel"
        aria-hidden="true"
        className="modal fade"
      >
        <div _ngcontent-ng-c2363829533="" className="modal-dialog">
          <div _ngcontent-ng-c2363829533="" className="modal-content">
            <div
              _ngcontent-ng-c2363829533=""
              className="modal-header bg-primary"
            >
              <h5 _ngcontent-ng-c2363829533="" className="modal-title">
                Device Details{" "}
              </h5>
              <button
                _ngcontent-ng-c2363829533=""
                type="button"
                className="close"
              >
                <span
                  _ngcontent-ng-c2363829533=""
                  aria-hidden="true"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  ×
                </span>
              </button>
            </div>
            <div _ngcontent-ng-c2363829533="" className="modal-body">
              <div _ngcontent-ng-c2363829533="" className="row linesInfo">
                <div _ngcontent-ng-c2363829533="" className="col-3">
                  <b _ngcontent-ng-c2363829533="">Device Info</b>
                </div>
                <div _ngcontent-ng-c2363829533="" className="col-9"></div>
              </div>
              <div _ngcontent-ng-c2363829533="" className="row linesInfo">
                <div _ngcontent-ng-c2363829533="" className="col-3">
                  <b _ngcontent-ng-c2363829533="">Ip Info</b>
                </div>
                <div _ngcontent-ng-c2363829533="" className="col-9"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-user-bet-history>
  );
}
