import React, { useEffect, useState } from "react";
import {
  getDeletedUsersAPI,
  handleDeleteRestoreUserAPI,
} from "../../service/AuthService";
import toast from "react-hot-toast";
import moment from "moment";

export default function Restore() {
  const [users, setUsers] = useState([]);
  const restoreUser = async (item) => {
    const { response, code, status } = await handleDeleteRestoreUserAPI(
      item?.uid,
      false
    );
    if (code == 200) {
      toast.success(status);

      window.location.reload();
    }
  };
  const getDeletedUsers = async () => {
    const { response } = await getDeletedUsersAPI();
    if (response) {
      setUsers(response);
    }
  };
  useEffect(() => {
    getDeletedUsers();
  }, []);
  return (
    <div _ngcontent-ng-c3796931036="" className="main">
      <div _ngcontent-ng-c3796931036="" className="container-fluid">
        <router-outlet _ngcontent-ng-c3796931036="" />
        <app-restore-user
          _nghost-ng-c3800297267=""
          className="ng-star-inserted"
        >
          <div
            _ngcontent-ng-c3800297267=""
            className="animated fadeIn divider-top"
          >
            <div _ngcontent-ng-c3800297267="" className="row">
              <div _ngcontent-ng-c3800297267="" className="col-sm-12 col-md-12">
                <div
                  _ngcontent-ng-c3800297267=""
                  className="card card-accent-primary"
                >
                  <div
                    _ngcontent-ng-c3800297267=""
                    className="card-header account-detail-head"
                  >
                    {" "}
                    Restore User
                  </div>
                  <div
                    _ngcontent-ng-c3800297267=""
                    className="card-body account-stat-body"
                  >
                    <div _ngcontent-ng-c3800297267="" className="row">
                      <div _ngcontent-ng-c3800297267="" className="col-md-12">
                        <div
                          _ngcontent-ng-c3800297267=""
                          className="table-responsive"
                        >
                          <div
                            id="DataTables_Table_1_wrapper"
                            className="dataTables_wrapper no-footer"
                          >
                            <div
                              className="dataTables_length"
                              id="DataTables_Table_1_length"
                            >
                              <label>
                                Show{" "}
                                <select
                                  name="DataTables_Table_1_length"
                                  aria-controls="DataTables_Table_1"
                                  className=""
                                >
                                  <option value={10}>10</option>
                                  <option value={25}>25</option>
                                  <option value={50}>50</option>
                                  <option value={100}>100</option>
                                </select>{" "}
                                entries
                              </label>
                            </div>
                            <div
                              id="DataTables_Table_1_filter"
                              className="dataTables_filter"
                            >
                              <label>
                                Search:
                                <input
                                  type="search"
                                  className=""
                                  placeholder=""
                                  aria-controls="DataTables_Table_1"
                                />
                              </label>
                            </div>
                            <table
                              _ngcontent-ng-c3800297267=""
                              datatable=""
                              role="table"
                              aria-busy="false"
                              aria-colcount={6}
                              className="table table-bordered table-striped dataTable no-footer"
                              id="DataTables_Table_1"
                              aria-describedby="DataTables_Table_1_info"
                            >
                              <thead
                                _ngcontent-ng-c3800297267=""
                                role="rowgroup"
                              >
                                <tr _ngcontent-ng-c3800297267="" role="row">
                                  <th
                                    _ngcontent-ng-c3800297267=""
                                    className="text-center sorting sorting_desc"
                                    tabIndex={0}
                                    aria-controls="DataTables_Table_1"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-sort="descending"
                                    aria-label="User Name: activate to sort column ascending"
                                  >
                                    User Name
                                  </th>
                                  <th
                                    _ngcontent-ng-c3800297267=""
                                    className="text-center sorting"
                                    tabIndex={0}
                                    aria-controls="DataTables_Table_1"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Name: activate to sort column ascending"
                                  >
                                    Name
                                  </th>
                                  <th
                                    _ngcontent-ng-c3800297267=""
                                    className="text-center sorting"
                                    tabIndex={0}
                                    aria-controls="DataTables_Table_1"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Date &amp; Time: activate to sort column ascending"
                                  >
                                    Date &amp; Time
                                  </th>
                                  <th
                                    _ngcontent-ng-c3800297267=""
                                    className="text-center sorting"
                                    tabIndex={0}
                                    aria-controls="DataTables_Table_1"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Action: activate to sort column ascending"
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="odd">
                                  <td
                                    valign="top"
                                    colSpan={4}
                                    className="dataTables_empty"
                                  >
                                    No matching records found
                                  </td>
                                </tr>
                              </tbody>
                              <tbody>
                                {users?.map((item) => (
                                  <tr
                                    _ngcontent-ng-c3800297267=""
                                    className="ng-star-inserted"
                                  >
                                    <td
                                      _ngcontent-ng-c3800297267=""
                                      className="border-right-black text-center"
                                    >
                                      <a _ngcontent-ng-c3800297267="">
                                        {item?.username}
                                      </a>
                                    </td>
                                    <td
                                      _ngcontent-ng-c3800297267=""
                                      className="border-right-black text-center"
                                    >
                                      <a _ngcontent-ng-c3800297267="">
                                        {item?.name}
                                      </a>
                                    </td>
                                    <td
                                      _ngcontent-ng-c3800297267=""
                                      className="text-center"
                                    >
                                      {moment(item?.createdAt).format(
                                        "MMM DD, YYYY, hh:mm:ss A"
                                      )}
                                    </td>
                                    <td
                                      _ngcontent-ng-c3800297267=""
                                      className="text-center"
                                    >
                                      <button
                                        onClick={() => restoreUser(item)}
                                        _ngcontent-ng-c3800297267=""
                                        className="btn btn-primary"
                                      >
                                        Restore
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div
                              className="dataTables_info"
                              id="DataTables_Table_1_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing {users?.length} to {users?.length} of{" "}
                            </div>
                            <div
                              className="dataTables_paginate paging_full_numbers"
                              id="DataTables_Table_1_paginate"
                            >
                              <a
                                className="paginate_button first disabled"
                                aria-controls="DataTables_Table_1"
                                aria-disabled="true"
                                role="link"
                                data-dt-idx="first"
                                tabIndex={-1}
                                id="DataTables_Table_1_first"
                              >
                                First
                              </a>
                              <a
                                className="paginate_button previous disabled"
                                aria-controls="DataTables_Table_1"
                                aria-disabled="true"
                                role="link"
                                data-dt-idx="previous"
                                tabIndex={-1}
                                id="DataTables_Table_1_previous"
                              >
                                Previous
                              </a>
                              <span>
                                <a
                                  className="paginate_button current"
                                  aria-controls="DataTables_Table_1"
                                  role="link"
                                  aria-current="page"
                                  data-dt-idx={0}
                                  tabIndex={0}
                                >
                                  1
                                </a>
                              </span>
                              <a
                                className="paginate_button next disabled"
                                aria-controls="DataTables_Table_1"
                                aria-disabled="true"
                                role="link"
                                data-dt-idx="next"
                                tabIndex={-1}
                                id="DataTables_Table_1_next"
                              >
                                Next
                              </a>
                              <a
                                className="paginate_button last disabled"
                                aria-controls="DataTables_Table_1"
                                aria-disabled="true"
                                role="link"
                                data-dt-idx="last"
                                tabIndex={-1}
                                id="DataTables_Table_1_last"
                              >
                                Last
                              </a>
                            </div>
                          </div>
                          {/**/}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </app-restore-user>
        {/**/}
      </div>
    </div>
  );
}
