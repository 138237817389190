import { Box, Button, Input, Select, Td, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { getBetHistoryAPI, searchUserAPI } from "../../service/AuthService";
import AsyncSelect from "react-select/async";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

export default function BetHistory({ userName }) {
  const [from, setFrom] = React.useState(
    moment().subtract(20, "days").format("YYYY-MM-DD")
  );
  const { state } = useLocation();
  const [to, setTo] = React.useState(moment().format("YYYY-MM-DD"));
  const [sport, setSport] = React.useState("CRICKET");
  const [matched, setMatched] = React.useState("MATCHED");
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [data, setData] = React.useState([]);

  const getBetHistory = async () => {
    let payload = {
      from: new Date(from),
      matched: matched == "MATCHED" ? true : false,
      page,
      size,
      sport,
      to: new Date(to),
      userName: userName,
    };
    const {
      response: { content },
    } = await getBetHistoryAPI(payload);
    let data_ = content.map((item, index) => ({
      ...item,

      bet_type: item.back_rate || item.back_run ? "Back" : "Lay",
      rate: item.back_run ? item.back_run : item.lay_run,
      "p/l": item.profit ? item.profit : item.loss,
    }));
    setData(data_);
  };
  const searchClient = (inputValue) => {
    return searchUserAPI(inputValue).then((response) => {
      return response.response.map((userName) => ({
        value: userName,
        label: userName,
      }));
    });
  };
  const navigate = useNavigate();
  useEffect(() => {
    getBetHistory();
  }, [size]);
  return (
    <app-bet-history _nghost-ng-c2274924760="" className="ng-star-inserted">
      <div _ngcontent-ng-c2274924760="" className="animated fadeIn divider-top">
        <div _ngcontent-ng-c2274924760="" className="row">
          <div _ngcontent-ng-c2274924760="" className="col-sm-12 col-md-12">
            <div _ngcontent-ng-c2274924760="" className="card card-report">
              <div _ngcontent-ng-c2274924760="" className="card-body row p-0">
                {/**/}
                <div
                  _ngcontent-ng-c2274924760=""
                  className="col-lg-2 col-md-4 col-6"
                >
                  <div _ngcontent-ng-c2274924760="" className="form-group mb-0">
                    <label
                      _ngcontent-ng-c2274924760=""
                      htmlFor="typechoose"
                      className="mb-2 marginLabel"
                    >
                      Choose Type
                    </label>
                    <div _ngcontent-ng-c2274924760="" className="input-group">
                      <select
                        _ngcontent-ng-c2274924760=""
                        name="type"
                        onChange={(e) => setMatched(e.target.value)}
                        id="typechoose"
                        
                        className="form-control form-select ng-untouched ng-pristine ng-valid"
                      >
                        <option _ngcontent-ng-c2274924760="" value="ALL">
                          ALL
                        </option>
                        <option _ngcontent-ng-c2274924760="" value="MATCHED">
                          MATCHED
                        </option>
                        <option _ngcontent-ng-c2274924760="" value="DELETED">
                          DELETED
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c2274924760=""
                  className="col-lg-2 col-md-4 col-6"
                >
                  <div _ngcontent-ng-c2274924760="" className="form-group mb-0">
                    <label
                      _ngcontent-ng-c2274924760=""
                      htmlFor="choosesport"
                      className="mb-2 marginLabel"
                    >
                      Choose Sport
                    </label>
                    <div _ngcontent-ng-c2274924760="" className="input-group">
                      <select
                        _ngcontent-ng-c2274924760=""
                        name="type"
                        id="choosesport"
                        value={sport}
                        onChange={(e) => setSport(e.target.value)}
                        className="sel form-control form-select ng-untouched ng-pristine ng-valid"
                      >
                        <option
                          _ngcontent-ng-c2274924760=""
                          value=""
                          disabled=""
                        >
                          Select Sport
                        </option>
                        <option
                          _ngcontent-ng-c2274924760=""
                          value={"ALL"}
                          className="ng-star-inserted"
                        >
                          {" "}
                          ALL{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2274924760=""
                          value={"CRICKET"}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Cricket{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2274924760=""
                          value={"TENNIS"}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Tennis{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2274924760=""
                          value={"CASINO"}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Casino{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2274924760=""
                          value={"SOCCER"}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Soccer{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2274924760=""
                          value={7}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Horse Racing{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2274924760=""
                          value={4339}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Greyhound Racing{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2274924760=""
                          value={27979456}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Kabaddi{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2274924760=""
                          value={7522}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Basketball{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2274924760=""
                          value={2378961}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Politics{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2274924760=""
                          value={66101}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Virtual Sports{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2274924760=""
                          value={66103}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Binary{" "}
                        </option>
                        <option
                          _ngcontent-ng-c2274924760=""
                          value={66104}
                          className="ng-star-inserted"
                        >
                          {" "}
                          Lottery{" "}
                        </option>
                        {/**/}
                      </select>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c2274924760=""
                  className="col-lg-2 col-md-4 col-6"
                >
                  <div _ngcontent-ng-c2274924760="" className="form-group mb-0">
                    <div
                      _ngcontent-ng-c2274924760=""
                      htmlFor="startDate"
                      className="mb-2 marginLabel"
                    >
                      From
                    </div>
                    <div _ngcontent-ng-c2274924760="" className="input-group">
                      <input
                        _ngcontent-ng-c2274924760=""
                        placeholder="Select Date"
                        name="mydate"
                        readOnly=""
                        type="date"
                        onChange={(e) => setFrom(e.target.value)}
                        value={moment(from).format("YYYY-MM-DD")}
                        autoComplete="off"
                        className="form-control dateClass ng-untouched ng-pristine ng-valid"
                        style={{ padding: "0px !important" }}
                      ></input>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c2274924760=""
                  className="col-lg-2 col-md-4 col-6"
                >
                  <div _ngcontent-ng-c2274924760="" className="form-group mb-0">
                    <div
                      _ngcontent-ng-c2274924760=""
                      htmlFor="endDate"
                      className="mb-2 marginLabel"
                    >
                      To
                    </div>
                    <input
                      _ngcontent-ng-c2274924760=""
                      placeholder="Select Date"
                      name="mydate"
                      readOnly=""
                      type="date"
                      onChange={(e) => setTo(e.target.value)}
                      value={moment(to).format("YYYY-MM-DD")}
                      autoComplete="off"
                      className="form-control dateClass ng-untouched ng-pristine ng-valid"
                      style={{ padding: "0px !important" }}
                    ></input>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c2274924760=""
                  className="col-lg-2 col-md-4 col-6"
                >
                  <div _ngcontent-ng-c2274924760="" className="form-group">
                    <div
                      _ngcontent-ng-c2274924760=""
                      htmlFor="type"
                      className="mobile-d-none"
                      style={{ width: "100%" }}
                    >
                      &nbsp;
                    </div>
                    <button
                      _ngcontent-ng-c2274924760=""
                      type="button"
                      onClick={getBetHistory}
                      className="btn btn-primary btnMrgn"
                    >
                      <strong _ngcontent-ng-c2274924760="">Get History</strong>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div _ngcontent-ng-c2274924760="" className="row">
          <div _ngcontent-ng-c2274924760="" className="col-sm-12 col-md-12">
            <div
              _ngcontent-ng-c2274924760=""
              className="card card-accent-primary"
            >
              <div
                _ngcontent-ng-c2274924760=""
                className="card-header account-detail-head"
              >
                {" "}
                Bet History{" "}
                <a
                  _ngcontent-ng-c2274924760=""
                  placement="right"
                  popover="Betting History is available online for the past 30 days."
                  triggers="click"
                >
                  <i
                    _ngcontent-ng-c2274924760=""
                    className="fa fa-question-circle"
                  />
                </a>
              </div>
              <div
                _ngcontent-ng-c2274924760=""
                className="card-body account-stat-body"
              >
                <div
                  _ngcontent-ng-c2274924760=""
                  className="table-responsive table table-striped"
                >
                  <div
                    id="DataTables_Table_27_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      className="dataTables_length"
                      id="DataTables_Table_27_length"
                    >
                      <label>
                        Show{" "}
                        <select
                          name="DataTables_Table_27_length"
                          aria-controls="DataTables_Table_27"
                          className=""
                          onChange={(e) => setSize(e.target.value)}
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>{" "}
                        entries
                      </label>
                    </div>
                    <div
                      id="DataTables_Table_27_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        Search:
                        <input
                          type="search"
                          className=""
                          placeholder=""
                          aria-controls="DataTables_Table_27"
                        />
                      </label>
                    </div>
                    <table
                      _ngcontent-ng-c2274924760=""
                      datatable=""
                      role="table"
                      aria-busy="false"
                      aria-colcount={6}
                      className="table table-bordered table-striped dataTable no-footer"
                      id="DataTables_Table_27"
                      aria-describedby="DataTables_Table_27_info"
                    >
                      <thead _ngcontent-ng-c2274924760="" role="rowgroup">
                        <tr _ngcontent-ng-c2274924760="" role="row">
                          <th
                            _ngcontent-ng-c2274924760=""
                            className="sorting sorting_desc"
                            tabIndex={0}
                            aria-controls="DataTables_Table_27"
                            rowSpan={1}
                            colSpan={1}
                            aria-sort="descending"
                            aria-label="User Name: activate to sort column ascending"
                          >
                            User Name
                          </th>
                          <th
                            _ngcontent-ng-c2274924760=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_27"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="SportName: activate to sort column ascending"
                          >
                            SportName
                          </th>
                          <th
                            _ngcontent-ng-c2274924760=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_27"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Event: activate to sort column ascending"
                          >
                            Event
                          </th>
                          <th
                            _ngcontent-ng-c2274924760=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_27"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Market: activate to sort column ascending"
                          >
                            Market
                          </th>
                          <th
                            _ngcontent-ng-c2274924760=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_27"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Selection: activate to sort column ascending"
                          >
                            Selection
                          </th>
                          <th
                            _ngcontent-ng-c2274924760=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_27"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Type: activate to sort column ascending"
                          >
                            Type
                          </th>
                          <th
                            _ngcontent-ng-c2274924760=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_27"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Odds Req.: activate to sort column ascending"
                          >
                            Odds Req.
                          </th>
                          <th
                            _ngcontent-ng-c2274924760=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_27"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Stack: activate to sort column ascending"
                          >
                            Stack
                          </th>
                          <th
                            _ngcontent-ng-c2274924760=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_27"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Place Time: activate to sort column ascending"
                          >
                            Place Time
                          </th>
                          <th
                            _ngcontent-ng-c2274924760=""
                            rowSpan={1}
                            colSpan={1}
                          >
                            Settle Time
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="odd">
                          <td
                            valign="top"
                            colSpan={9}
                            className="dataTables_empty"
                          >
                            No data available in table
                          </td>
                        </tr>
                      </tbody>
                      {/**/}
                      <tbody>
                        {data?.map((item, index) => (
                          <tr
                            _ngcontent-ng-c2274924760=""
                            role="row"
                            className="odd ng-star-inserted"
                          >
                            <td
                              _ngcontent-ng-c2274924760=""
                              className="ng-star-inserted"
                            >
                              {state.username}
                            </td>
                            <td
                              _ngcontent-ng-c2274924760=""
                              className="ng-star-inserted"
                            >
                              {sport}
                            </td>
                            <td
                              _ngcontent-ng-c2274924760=""
                              className="ng-star-inserted"
                            >
                              {item.event_name}
                            </td>
                            <td
                              _ngcontent-ng-c2274924760=""
                              className="ng-star-inserted"
                            >
                              {item.marketName}
                            </td>
                            <td
                              _ngcontent-ng-c2274924760=""
                              className="ng-star-inserted"
                            >
                              {item.runner_name}
                            </td>
                            <td
                              _ngcontent-ng-c2274924760=""
                              className="ng-star-inserted"
                            >
                              {item.bet_type}
                            </td>
                            <td
                              _ngcontent-ng-c2274924760=""
                              className="ng-star-inserted"
                            >
                              {item.rate}
                            </td>
                            <td
                              _ngcontent-ng-c2274924760=""
                              className="ng-star-inserted"
                            >
                              {item.amount}
                            </td>
                            <td
                              _ngcontent-ng-c2274924760=""
                              className="ng-star-inserted"
                            >
                              {moment(item.created_date+"Z").format(
                                "DD-MM-YYYY HH:mm"
                              )}
                            </td>
                            <td
                              _ngcontent-ng-c2274924760=""
                              className="ng-star-inserted"
                            >
                              
                            </td>
                          </tr>
                        ))}
                        {data?.length == 0 && (
                          <tr
                            _ngcontent-ng-c2274924760=""
                            className="odd ng-star-inserted"
                          >
                            <td
                              _ngcontent-ng-c2274924760=""
                              colSpan={10}
                              className="no-data-available"
                            >
                              No data!
                            </td>
                          </tr>
                        )}
                        {/**/}
                      </tbody>
                    </table>
                    <div
                      className="dataTables_info"
                      id="DataTables_Table_27_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing 0 to 0 of 0 entries
                    </div>
                    <div
                      className="dataTables_paginate paging_full_numbers"
                      id="DataTables_Table_27_paginate"
                    >
                      <a
                        className="paginate_button first disabled"
                        aria-controls="DataTables_Table_27"
                        aria-disabled="true"
                        role="link"
                        data-dt-idx="first"
                        tabIndex={-1}
                        id="DataTables_Table_27_first"
                      >
                        First
                      </a>
                      <a
                        className="paginate_button previous disabled"
                        aria-controls="DataTables_Table_27"
                        aria-disabled="true"
                        role="link"
                        data-dt-idx="previous"
                        tabIndex={-1}
                        id="DataTables_Table_27_previous"
                      >
                        Previous
                      </a>
                      <span />
                      <a
                        className="paginate_button next disabled"
                        aria-controls="DataTables_Table_27"
                        aria-disabled="true"
                        role="link"
                        data-dt-idx="next"
                        tabIndex={-1}
                        id="DataTables_Table_27_next"
                      >
                        Next
                      </a>
                      <a
                        className="paginate_button last disabled"
                        aria-controls="DataTables_Table_27"
                        aria-disabled="true"
                        role="link"
                        data-dt-idx="last"
                        tabIndex={-1}
                        id="DataTables_Table_27_last"
                      >
                        Last
                      </a>
                    </div>
                  </div>
                  {/**/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-bet-history>
  );
}
