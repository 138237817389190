import {
  AddIcon,
  ArrowDownIcon,
  ChevronDownIcon,
  EditIcon,
  ExternalLinkIcon,
  HamburgerIcon,
  RepeatIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,

} from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "../redux/store";
import "../pages/Game/all.css";
import "../pages/Game/game.css";
import AsyncSelect from "react-select/async";
import {
  getUserDataAPI,
  getUserDetailsAPI,
  searchUserAPI,
} from "../service/AuthService";
import toast from "react-hot-toast";
import { ROLE_MAPPING } from "../utils/constants";
export default function Header({ drawerButtonRef, onOpen }) {
  const [userName, setUserName] = React.useState("");
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState({});

  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.account.userData);
  const role = useSelector((state) => state.account.userData.userType);
  const searchClient = (inputValue) => {
    return searchUserAPI(inputValue)
      .then((response) => {
        return response.response.map((userName) => ({
          value: userName,
          label: userName,
        }));
      })
      .catch((error) => {
        dispatch({
          type: "accountData/logOut",
          payload: {},
        });
        window.localStorage.clear();
        window.location.href = "/";
      });
  };
  const getUserDetail = async (userName) => {
    setIsLoading(true);
    const { response, message, code } = await getUserDetailsAPI(userName);
    if (code == 200) {
      setIsModalOpen(true);
      setUserDetails(response);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      toast({
        title: "Error",
        description: response,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const getAccountDetails = async () => {
    const { response } = await getUserDataAPI(
      store.getState().account.userData.id
    );
    setIsLoading(false);
    dispatch({
      type: "accountData/setUserData",
      payload: response,
    });
  };
  useEffect(() => {
    setUserName(store.getState().account.userData.username);
    const interval = setInterval(() => {
      getAccountDetails();
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  const handleLogout = () => {
    dispatch({
      type: "accountData/logOut",
      payload: {},
    });
    window.localStorage.clear();
    window.location.href = "/";
  };
  return (
    <app-header _ngcontent-ng-c3796931036="" _nghost-ng-c860675100="">
      <div _ngcontent-ng-c860675100="" className="top prevent-select">
        <div _ngcontent-ng-c860675100="" className="header">
          <h1 _ngcontent-ng-c860675100="">
            <a
              _ngcontent-ng-c860675100=""
              routerlink="/dashboard"
              className="active"
              href="/dashboard"
            >
              <img
                _ngcontent-ng-c860675100=""
                id="logoMain"
                className="logo-main ng-star-inserted"
                src="./logo.png"
              />
              {/**/}
            </a>
          </h1>
          <div
            _ngcontent-ng-c860675100=""
            className="AccountWrap d-none-mobile"
          >
            <ul _ngcontent-ng-c860675100="" className="account-wrap">
              <li _ngcontent-ng-c860675100="">
                <span _ngcontent-ng-c860675100="" className="userrole">
                  {userData?.userType}
                </span>
                <strong _ngcontent-ng-c860675100="">
                  {" "}
                  {userData?.username}
                </strong>
              </li>

              <li _ngcontent-ng-c860675100="" className="ng-star-inserted">
                {isLoading ? (
                  <div
                    _ngcontent-ng-c1591630683=""
                    className="loadingBalance ng-star-inserted"
                  >
                    <span _ngcontent-ng-c1591630683="" />
                    <span _ngcontent-ng-c1591630683="" />
                    <span _ngcontent-ng-c1591630683="" />
                    <span _ngcontent-ng-c1591630683="" />
                    <span _ngcontent-ng-c1591630683="" />
                    <span _ngcontent-ng-c1591630683="" />
                    <span _ngcontent-ng-c1591630683="" />
                  </div>
                ) : (
                  <strong _ngcontent-ng-c860675100="" id="mainBalance">
                    BAL. {userData?.balance}
                  </strong>
                )}
              </li>
              {/**/}
              <li _ngcontent-ng-c860675100="">{/**/}</li>
              <li
                onClick={() => {
                  setIsLoading(true);
                  getAccountDetails();
                  toast.success("Account details updated");
                }}
                _ngcontent-ng-c860675100=""
                className="head-refresh"
              >
                <a _ngcontent-ng-c860675100="" style={{ cursor: "pointer" }}>
                  <i _ngcontent-ng-c860675100="" className="fas fa-undo-alt" />
                </a>
              </li>
            </ul>
          </div>
          <div
            _ngcontent-ng-c860675100=""
            className="d-none-desktop AccountWrap account-wrap w-100"
          >
            <div
              _ngcontent-ng-c860675100=""
              className="d-flex flex-column align-items-end justify-content-start me-2"
            >
              <div _ngcontent-ng-c860675100="">
                <span _ngcontent-ng-c860675100="" className="accountRole">
                  {userData?.userType}
                </span>
                <strong _ngcontent-ng-c860675100="">
                  {" "}
                  {userData?.username}
                </strong>
              </div>
              <div
                _ngcontent-ng-c860675100=""
                className="d-flex jusitfy-content-between align-items-center mt-1"
              >
                {isLoading ? (
                  <div
                    _ngcontent-ng-c1591630683=""
                    className="loadingBalance ng-star-inserted"
                  >
                    <span _ngcontent-ng-c1591630683="" />
                    <span _ngcontent-ng-c1591630683="" />
                    <span _ngcontent-ng-c1591630683="" />
                    <span _ngcontent-ng-c1591630683="" />
                    <span _ngcontent-ng-c1591630683="" />
                    <span _ngcontent-ng-c1591630683="" />
                    <span _ngcontent-ng-c1591630683="" />
                  </div>
                ) : (
                  <div _ngcontent-ng-c860675100="" className="ng-star-inserted">
                    <strong _ngcontent-ng-c860675100="" id="mainBalance">
                      BAL.{" "}
                      {userData?.userType == "ADMIN"
                        ? "INF"
                        : userData?.balance}
                    </strong>
                  </div>
                )}
                {/**/}
                {/**/}
                <div
                  onClick={() => {
                    setIsLoading(true);
                   getAccountDetails().then(() => {
                     setIsLoading(false);
                   });
                    toast.success("Account details updated");
                  }}
                  _ngcontent-ng-c860675100=""
                  className="head-refresh"
                >
                  <a _ngcontent-ng-c860675100="" style={{ cursor: "pointer" }}>
                    <i
                      _ngcontent-ng-c860675100=""
                      className="fas fa-undo-alt"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div _ngcontent-ng-c860675100="" className="menu-wrap">
          <div _ngcontent-ng-c860675100="" className="main_wrap">
            <ul _ngcontent-ng-c860675100="" className="menu">
              <li
                _ngcontent-ng-c860675100=""
                routerlinkactive="select"
                id="dashboard"
                className="select"
              >
                <a
                  _ngcontent-ng-c860675100=""
                  routerlink="/dashboard"
                  href="/dashboard"
                >
                  Dashboard{" "}
                </a>
              </li>
              <li
                _ngcontent-ng-c860675100=""
                routerlinkactive="select"
                id="donwline"
                className="dropdown"
              >
                <a
                  _ngcontent-ng-c860675100=""
                  id="dropdownMenuButton1Downline"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  className="menu-drop dropdown-toggle"
                >
                  Downline List{" "}
                </a>
                <ul
                  _ngcontent-ng-c860675100=""
                  aria-labelledby="dropdownMenuButton1Downline"
                  className="dropdown-menu"
                >
                  <li _ngcontent-ng-c860675100="">
                    <a
                      _ngcontent-ng-c860675100=""
                      routerlink="/list/user"
                      className="dropdown-item"
                      href={"/list/CLIENT"}
                    >
                      User Downline List
                    </a>
                  </li>
                  <li _ngcontent-ng-c860675100="">
                    <a
                      _ngcontent-ng-c860675100=""
                      routerlink="/list/user"
                      className="dropdown-item"
                      href={"/list/MASTER"}
                    >
                      Master Downline List
                    </a>
                  </li>
                </ul>
              </li>
              <li
                _ngcontent-ng-c860675100=""
                routerlinkactive="select"
                id="myAccount"
              >
                <a
                  _ngcontent-ng-c860675100=""
                  routerlink="/my-account/profile/master"
                  href="/my-account/profile"
                >
                  My Account{" "}
                </a>
              </li>
              <li
                _ngcontent-ng-c860675100=""
                routerlinkactive="select"
                id="myReport"
                className="nav-item dropdown"
              >
                <a
                  _ngcontent-ng-c860675100=""
                  data-bs-toggle="dropdown"
                  id="dropdownMenuButton1Reports"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="nav-link dropdown-toggle"
                >
                  My Report
                  <span _ngcontent-ng-c860675100="" className="caret" />
                </a>
                <div
                  _ngcontent-ng-c860675100=""
                  aria-labelledby="dropdownMenuButton1Reports"
                  className="dropdown-menu Newdropdonw"
                >
                  <a
                    _ngcontent-ng-c860675100=""
                    routerlink="/reports/profit-loss"
                    className="dropdown-item nav-link dropopColor"
                    href="/reports/profit-loss"
                  >
                    Event Profit/Loss{" "}
                  </a>
                  <a
                    _ngcontent-ng-c860675100=""
                    routerlink="/reports/downline-pl"
                    className="dropdown-item nav-link dropopColor"
                    href="/reports/downline-pl"
                  >
                    Downline Profit/Loss{" "}
                  </a>
                </div>
              </li>
              <li
                _ngcontent-ng-c860675100=""
                routerlinkactive="select"
                id="betlist"
              >
                <a
                  _ngcontent-ng-c860675100=""
                  routerlink="/bet-list"
                  href="/bet-list"
                >
                  BetList{" "}
                </a>
              </li>
              <li
                _ngcontent-ng-c860675100=""
                routerlinkactive="select"
                id="marketAnalysis"
              >
                <a
                  _ngcontent-ng-c860675100=""
                  routerlink="/market-analysis"
                  href="/market-analysis"
                >
                  Market Analysis{" "}
                </a>
              </li>
              <li
                _ngcontent-ng-c860675100=""
                routerlinkactive="select"
                id="banking"
                className="nav-item dropdown"
              >
                <a
                  _ngcontent-ng-c860675100=""
                  data-bs-toggle="dropdown"
                  id="dropdownMenuButton1Banking"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="nav-link dropdown-toggle"
                >
                  Banking
                  <span _ngcontent-ng-c860675100="" className="caret" />
                </a>
                <div
                  _ngcontent-ng-c860675100=""
                  aria-labelledby="dropdownMenuButton1Banking"
                  className="dropdown-menu Newdropdonw"
                >
                  {ROLE_MAPPING[role].map((item) => (
                    <a
                      _ngcontent-ng-c860675100=""
                      routerlink="/user-banking"
                      className="dropdown-item nav-link dropopColor"
                      href={"/banking/" + item.value}
                    >
                      {item.label} Banking
                    </a>
                  ))}
                </div>
              </li>
              <li
                _ngcontent-ng-c860675100=""
                routerlinkactive="select"
                id="Commission"
              >
                <a
                  _ngcontent-ng-c860675100=""
                  routerlink="/commission"
                  href="/commission"
                >
                  Commission{" "}
                </a>
              </li>
              {userData?.userType != "AGENT" && (
                <li
                  _ngcontent-ng-c860675100=""
                  routerlinkactive="select"
                  id="password-history"
                >
                  <a
                    _ngcontent-ng-c860675100=""
                    routerlink="/password-history"
                    href="/password-history"
                  >
                    Password History{" "}
                  </a>
                </li>
              )}
              <li
                _ngcontent-ng-c860675100=""
                routerlinkactive="select"
                id="resotreuser"
              >
                <a
                  _ngcontent-ng-c860675100=""
                  routerlink="/restore-user"
                  href="/restore-user"
                >
                  Restore User{" "}
                </a>
              </li>
              {/**/}
              <li _ngcontent-ng-c860675100="" className="logout">
                <a onClick={handleLogout} _ngcontent-ng-c860675100="">
                  Logout{" "}
                  <svg
                    _ngcontent-ng-c860675100=""
                    width={10}
                    height={11}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      _ngcontent-ng-c860675100=""
                      d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z"
                      fill="currentColor"
                      fillRule="evenodd"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </app-header>
  );

  return (
    <header>
      <div class="header-bottom">
        <div class="container-fluid">
          <a href="/admin/manage-client/list" class="logo">
            <img src={require("../assets/img/logo.png")} />
          </a>
          <div
            ref={drawerButtonRef}
            style={{
              position: "absolute",
              left: "135px",
              top: "15px",
              zIndex: 10,
            }}
            onClick={onOpen}
            class="side-menu-button"
          >
            <div class="bar1"></div>
            <div class="bar2"></div>
            <div class="bar3"></div>
          </div>

          <Modal
            blockScrollOnMount={false}
            scrollBehavior="outside"
            isOpen={isModalOpen}
            onClose={() => {
              setIsModalOpen(false);
            }}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Modal Title</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <div
                  className="modal fade show"
                  id="modal-user-detail"
                  style={{ display: "block" }}
                >
                  <div className="modal-dialog modal-lg">
                    <div
                      style={{
                        overflowY: "auto",
                        height: "80vh",
                      }}
                      className="modal-content"
                    >
                      <div className="modal-header">
                        <h4 className="modal-title">User Detail</h4>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          onClick={() => {
                            setIsModalOpen(false);
                          }}
                        >
                          ×
                        </button>
                      </div>
                      <div className="modal-body dark" id="search-user-details">
                        <div className="row">
                          <div className="col-md-6 m-t-10">
                            <h4 className="m-b-10 col-md-12">Game Lock</h4>
                            <table className="table table-striped table-dark">
                              <thead>
                                <tr>
                                  <th>EventName</th>
                                  <th>UserName</th>
                                  <th>UserActive</th>
                                  <th>BetActive</th>
                                  <th>FancyActive</th>
                                </tr>
                              </thead>
                            </table>
                          </div>
                          <div className="col-md-6 m-t-10">
                            <h4 className="m-b-10 col-md-12">User Lock</h4>
                            <table className="table table-striped table-dark">
                              <thead>
                                <tr>
                                  <th>UserName</th>
                                  <th>AccountType</th>
                                  <th>UserActive</th>
                                  <th>BetActive</th>
                                </tr>
                              </thead>
                              <tbody>
                                {userDetails?.userLockUserDetailsDTOS?.map(
                                  (user) => (
                                    <tr>
                                      {" "}
                                      <td>{user.userName}</td>{" "}
                                      <td>{user.userType}</td>{" "}
                                      <td>
                                        <label className="form-check-label">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            disabled
                                            defaultChecked="checked"
                                          />
                                          {user.userActive && (
                                            <span className="checkmark" />
                                          )}
                                        </label>
                                      </td>{" "}
                                      <td>
                                        <label className="form-check-label">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            disabled
                                            defaultChecked="checked"
                                          />
                                          {user.betActive && (
                                            <span className="checkmark" />
                                          )}
                                        </label>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="col-md-12 m-t-10">
                            <h4 className="m-b-10 col-md-12">Game Book</h4>
                            <table className="table table-striped table-dark">
                              <thead>
                                <tr>
                                  <th>Gametype</th>
                                  <th>EventName</th>
                                  <th>BookA</th>
                                  <th>NationA</th>
                                  <th>BookB</th>
                                  <th>NationB</th>
                                  <th>BookC</th>
                                  <th>NationC</th>
                                  <th>NoOfBet</th>
                                </tr>
                              </thead>
                              <tbody>
                                {userDetails?.gameBookUserDetailsDTOS?.map(
                                  (user) => (
                                    <tr>
                                      <td>{user.gameType}</td>
                                      <td>{user.eventName}</td>
                                      <td>{user.bookA}</td>
                                      <td>{user.nationA}</td>
                                      <td>{user.bookB}</td>
                                      <td>{user.nationB}</td>
                                      <td>{user.bookC}</td>
                                      <td>{user.nationC}</td>
                                      <td>{user.noOrBet}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="col-md-6 m-t-10">
                            <h4 className="m-b-10 col-md-12">User Details</h4>
                            <table className="table table-striped table-dark">
                              <thead>
                                <tr>
                                  <th>UserName</th>
                                  <th>Exposer</th>
                                  <th>Creditref</th>
                                  <th>ExpoLimits</th>
                                  <th>General</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {
                                      userDetails?.accountDataUserDetailsDTO
                                        ?.userName
                                    }
                                  </td>{" "}
                                  <td>
                                    {
                                      userDetails?.accountDataUserDetailsDTO
                                        ?.exposure
                                    }
                                  </td>{" "}
                                  <td>
                                    {
                                      userDetails?.accountDataUserDetailsDTO
                                        ?.creditReference
                                    }
                                  </td>{" "}
                                  <td>
                                    {
                                      userDetails?.accountDataUserDetailsDTO
                                        ?.exposureLimit
                                    }
                                  </td>{" "}
                                  <td>
                                    {
                                      userDetails?.accountDataUserDetailsDTO
                                        ?.balance
                                    }
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-md-6 m-t-10">
                            <h4 className="m-b-10 col-md-12">Fancy Book</h4>
                            <table className="table table-striped table-dark">
                              <thead>
                                <tr>
                                  <th>EventName</th>
                                  <th>Book</th>
                                </tr>
                              </thead>
                              <tbody>
                                {userDetails?.fancyBookUserDetailsDTOS?.map(
                                  (user) => (
                                    <tr>
                                      <td>{user.eventName}</td>
                                      <td>{user.book}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </ModalContent>
          </Modal>
          <nav class="navbar navbar-expand-md btco-hover-menu">
            <div class="collapse navbar-collapse">
              <ul class="list-unstyled navbar-nav">
                <li class="nav-item">
                  <a href="/admin/manage-client/active-list">List of clients</a>
                </li>

                <li class="nav-item">
                  <a href="/admin/market-analysis">Market Analysis</a>
                </li>
                <li class="nav-item dropdown newlacunch-menu">
                  <a href="javascript:void(0);">
                    <span>
                      Live Market <ChevronDownIcon />
                    </span>
                  </a>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <li>
                      <a class="dropdown-item" href="/admin/live-market/race20">
                        <span>Race 20-20</span>
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/admin/live-market/queen">
                        <span>Queen</span>
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        href="/admin/live-market/baccarat/listing"
                      >
                        <span>Baccarat</span>
                      </a>
                    </li>
                    <li class="newlacunch-menu">
                      <a
                        class="dropdown-item"
                        href="/admin/live-market/sports-casino"
                      >
                        <span>Sports Casino</span>
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        href="/admin/live-market/threecards"
                      >
                        <span>3 Cards Judgement</span>
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/admin/live-market/card32">
                        <span>32 Cards Casino</span>
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        href="/admin/live-market/teenpatti"
                      >
                        <span>Live TeenPatti</span>
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/admin/live-market/poker">
                        <span>Live Poker</span>
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        href="/admin/live-market/andarbahar"
                      >
                        <span>Andar Bahar</span>
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/admin/live-market/lucky7">
                        <span>Lucky 7</span>
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/admin/live-market/dt">
                        <span>Dragon Tiger</span>
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        href="/admin/live-market/bollywood-casino"
                      >
                        <span>Bollywood Casino</span>
                      </a>
                    </li>

                    <li>
                      <a class="dropdown-item" href="/admin/live-market/cmeter">
                        <span>Casino Meter</span>
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/admin/live-market/war">
                        <span>Casino War</span>
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        href="/admin/live-market/instant_worli/list1"
                      >
                        <span>Worli</span>
                      </a>
                    </li>
                  </ul>
                </li>

                <li class="nav-item dropdown">
                  <a href="javascript:void(0);">
                    Reports <i class="fa fa-caret-down"></i>
                  </a>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <li>
                      <a
                        href="/admin/reports/current-bet"
                        class="dropdown-item"
                      >
                        Current Bets
                      </a>
                    </li>
                    <li>
                      <a
                        href="/admin/reports/account-statement"
                        class="dropdown-item"
                      >
                        Account's Statement
                      </a>
                    </li>
                    <li>
                      <a
                        href="/admin/reports/bet-history"
                        class="dropdown-item"
                      >
                        Bet History
                      </a>
                    </li>
                    <li>
                      <a
                        href="/admin/reports/general-report"
                        class="dropdown-item"
                      >
                        General Report
                      </a>
                    </li>
                    <li>
                      <a
                        href="/admin/reports/game-report"
                        class="dropdown-item"
                      >
                        Game Report
                      </a>
                    </li>
                    <li>
                      <a
                        href="/admin/reports/profit-loss"
                        class="dropdown-item"
                      >
                        Profit And Loss
                      </a>
                    </li>
                    <li>
                      <a
                        href="/admin/reports/casino-results"
                        class="dropdown-item"
                      >
                        Casino Result Report
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul class="user-search list-unstyled">
                <li class="username btco-hover-menu">
                  <span>
                    {userName} <ChevronDownIcon />
                  </span>
                  <ul>
                    <li>
                      <a href="/admin/change-password" class="dropdown-item">
                        Change password
                      </a>
                    </li>
                    <li>
                      <a href="/admin/security" class="dropdown-item">
                        Security Auth
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          dispatch({
                            type: "accountData/logOut",
                            payload: {},
                          });
                          window.localStorage.clear();
                          window.location.href = "/";
                        }}
                        class="dropdown-item"
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="search">
                  <AsyncSelect
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: "none",
                        boxShadow: "none",
                        height: "30px",
                        width: "100px",
                      }),
                      input: (base) => ({
                        ...base,
                        height: "30px",
                        width: "100px",
                      }),

                      indicatorSeparator: (base) => ({
                        ...base,
                        display: "none",
                      }),
                      dropdownIndicator: (base) => ({
                        ...base,
                        display: "none",
                      }),
                      //incraese the width and don't make it overflow
                      menu: (base) => ({
                        ...base,
                        width: "100px",
                        overflow: "hidden",
                        color: "black",
                      }),
                    }}
                    cacheOptions
                    defaultOptions
                    loadOptions={searchClient}
                    onChange={(e) => getUserDetail(e.value)}
                    type="text"
                    name="search-account_details"
                    id="search-account_details"
                    placeholder="All Client"
                    class="ui-autocomplete-input"
                    autocomplete="off"
                  />
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}
