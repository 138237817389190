import { Box, Button, Input, Select, Td, Text } from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import CustomInput from "../../components/UI/CustomInput";
import CustomSelect from "../../components/UI/CustomSelect";
import CustomTable from "../../components/UI/Table";

import { getActivityLogAPI } from "../../service/AuthService";
import {
  getCurrentDateTimeLocal,
  searchSubstringInArrayObjects,
} from "../../utils/constants";
import moment from "moment/moment";

export default function ActivityLog({ userName }) {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalPages, setTotalPages] = React.useState(0);
  const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([]);
  const [totalElements, setTotalElements] = React.useState(0);
  const [type, setType] = React.useState("LOGIN");
  const location = useLocation();
  const [startDate, setStartDate] = React.useState(
    moment().subtract(7, "days").format("YYYY-MM-DDTHH:mm")
  );

  const [endDate, setEndDate] = React.useState(
    moment().format("YYYY-MM-DDTHH:mm")
  );
  const getActivityLog = async () => {
    let endDate_ = new Date(endDate);
    endDate_.setDate(endDate_.getDate() + 1);
    let payload = {
      pageSize: rowsPerPage,
      from: new Date(startDate),
      to: endDate_,
      activityLogType: type,
      userName,
      pageNumber,
    };
    const {
      response: {
        content,
        size,
        totalElements,
        totalPages,
        number,
        numberOfElements,
      },
      code,
    } = await getActivityLogAPI(payload);
    if (code !== 200) return;
    setData(content);
    setTotalPages(totalPages);
    setLoading(false);
    setRowsPerPage(size);
    setTotalElements(totalElements);
  };
  useEffect(() => {
    getActivityLog();
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    getActivityLog();
  };
  const search = async (e) => {
    if (e.target.value == "") return getActivityLog();
    setData(
      searchSubstringInArrayObjects(
        data,
        ["userName", "ipAddress"],
        e.target.value
      )
    );
  };
  return (
    <app-activitylog _nghost-ng-c2930918176="" className="ng-star-inserted">
      <div _ngcontent-ng-c2930918176="" className="animated fadeIn divider-top">
        <div _ngcontent-ng-c2930918176="" className="card card-accent-primary">
          <div _ngcontent-ng-c2930918176="" className="game-heading">
            <span _ngcontent-ng-c2930918176="" className="card-header-title">
              Activity Log
            </span>
          </div>
          <div
            _ngcontent-ng-c2930918176=""
            className="card-body account-detail-body table-responsive"
          >
            <table
              _ngcontent-ng-c2930918176=""
              id="example"
              className="display nowrap table table-striped table-bordered"
              style={{ width: "100%" }}
            >
              <thead _ngcontent-ng-c2930918176="">
                <tr _ngcontent-ng-c2930918176="" role="row">
                  <th
                    _ngcontent-ng-c2930918176=""
                    role="columnheader"
                    scope="col"
                    aria-colindex={1}
                    className="text-center"
                  >
                    Login&nbsp;Date &amp; Time
                  </th>
                  <th
                    _ngcontent-ng-c2930918176=""
                    role="columnheader"
                    scope="col"
                    aria-colindex={2}
                    className="text-center"
                  >
                    Login Status{" "}
                  </th>
                  <th
                    _ngcontent-ng-c2930918176=""
                    role="columnheader"
                    scope="col"
                    aria-colindex={3}
                    className="text-center"
                  >
                    IP Address{" "}
                  </th>
                  <th
                    _ngcontent-ng-c2930918176=""
                    role="columnheader"
                    scope="col"
                    aria-colindex={4}
                    className="text-center"
                  >
                    ISP{" "}
                  </th>
                  <th
                    _ngcontent-ng-c2930918176=""
                    role="columnheader"
                    scope="col"
                    aria-colindex={5}
                    className="text-center"
                  >
                    {" "}
                    City/State/Country
                  </th>
                </tr>
              </thead>
              <tbody _ngcontent-ng-c2930918176="" className="table-responsive">
                {data.map((item, index) => (
                  <tr
                    _ngcontent-ng-c2930918176=""
                    role="row"
                    className={index % 2 === 0 ? "even" : "odd"}
                  >
                    <td _ngcontent-ng-c2930918176="" className="text-center">
                      {getCurrentDateTimeLocal(item.createdDate)}
                    </td>
                    <td _ngcontent-ng-c2930918176="" className="text-center">
                      {item.activityLogType}
                    </td>
                    <td _ngcontent-ng-c2930918176="" className="text-center">
                      {item.ipAddress}
                    </td>
                    <td _ngcontent-ng-c2930918176="" className="text-center">
                      {item.isp}
                    </td>
                    <td _ngcontent-ng-c2930918176="" className="text-center">
                      {item.city}/{item.state}/{item.country}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </app-activitylog>
  );
}
