import React from "react";

export default function Commission() {
  return (
    <app-commission _nghost-ng-c1664726108="" className="ng-star-inserted">
      <div _ngcontent-ng-c1664726108="" className="animated fadeIn divider-top">
        <div _ngcontent-ng-c1664726108="" className="row">
          <div _ngcontent-ng-c1664726108="" className="col-sm-12 col-md-12">
            <div _ngcontent-ng-c1664726108="" className="card card-report">
              <div _ngcontent-ng-c1664726108="" className="card-body row p-0">
                <div _ngcontent-ng-c1664726108="" className="col-lg-2 col-md-4">
                  <div
                    _ngcontent-ng-c1664726108=""
                    className="col-md-12 col-12"
                  >
                    <div
                      _ngcontent-ng-c1664726108=""
                      className="form-group m-0"
                    >
                      <my-date-picker
                        _ngcontent-ng-c1664726108=""
                        name="mydate"
                        required=""
                        className="ng-untouched dateClass ng-pristine ng-valid"
                      >
                        <div className="mydp" style={{ width: "100%" }}>
                          <div className="selectiongroup ng-star-inserted">
                            <input
                              ngtype="text"
                              autoComplete="off"
                              spellCheck="false"
                              autoCorrect="off"
                              className="selection inputnoteditable ng-untouched ng-pristine ng-valid ng-star-inserted"
                              placeholder=""
                              type="date"
                              readOnly=""
                              aria-label="Date input field"
                              style={{ height: 34, fontSize: 16 }}
                            />
                            {/**/}
                            <div className="selbtngroup" style={{ height: 34 }}>
                              {/**/}
                              {/**/}
                              {/**/}
                              <button
                                type="button"
                                className="btnpicker btnpickerenabled"
                                aria-label="Open Calendar"
                              >
                                <span className="mydpicon icon-mydpcalendar" />
                              </button>
                            </div>
                          </div>
                          {/**/}
                          {/**/}
                        </div>
                      </my-date-picker>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1664726108=""
                  className="col-lg-1 col-md-1 p-0 col-12"
                >
                  <div
                    _ngcontent-ng-c1664726108=""
                    className="form-group textTO m-2"
                  >
                    <span _ngcontent-ng-c1664726108="" className="lbl-to">
                      {" "}
                      TO
                    </span>
                  </div>
                </div>
                <div _ngcontent-ng-c1664726108="" className="col-lg-2 col-md-4">
                  <div
                    _ngcontent-ng-c1664726108=""
                    className="col-md-12 col-12"
                  >
                    <div _ngcontent-ng-c1664726108="" className="form-group">
                      <my-date-picker
                        _ngcontent-ng-c1664726108=""
                        name="enddate"
                        required=""
                        className="ng-untouched dateClass ng-pristine ng-valid"
                      >
                        <div className="mydp" style={{ width: "100%" }}>
                          <div className="selectiongroup ng-star-inserted">
                            <input
                              ngtype="text"
                              autoComplete="off"
                              spellCheck="false"
                              autoCorrect="off"
                              className="selection inputnoteditable ng-untouched ng-pristine ng-valid ng-star-inserted"
                              placeholder=""
                              readOnly=""
                              aria-label="Date input field"
                              style={{ height: 34, fontSize: 16 }}
                            />
                            {/**/}
                            <div className="selbtngroup" style={{ height: 34 }}>
                              {/**/}
                              {/**/}
                              {/**/}
                              <button
                                type="button"
                                className="btnpicker btnpickerenabled"
                                aria-label="Open Calendar"
                              >
                                <span className="mydpicon icon-mydpcalendar" />
                              </button>
                            </div>
                          </div>
                          {/**/}
                          {/**/}
                        </div>
                      </my-date-picker>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1664726108=""
                  className="col-lg-2 col-md-3 d-flex justify-content-center align-items-center"
                >
                  <div
                    _ngcontent-ng-c1664726108=""
                    className="form-group reportbtn"
                  >
                    <button
                      _ngcontent-ng-c1664726108=""
                      type="button"
                      className="btn btn-primary"
                    >
                      <strong _ngcontent-ng-c1664726108="">
                        Get Commission
                      </strong>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div _ngcontent-ng-c1664726108="" className="row">
          <div _ngcontent-ng-c1664726108="" className="col-sm-12 col-md-12">
            <div
              _ngcontent-ng-c1664726108=""
              className="card card-accent-primary"
            >
              <div
                _ngcontent-ng-c1664726108=""
                className="card-header account-detail-head"
              >
                {" "}
                Agent Commission
              </div>
              <div
                _ngcontent-ng-c1664726108=""
                className="card-body account-stat-body"
              >
                <div _ngcontent-ng-c1664726108="" className="row">
                  <div
                    _ngcontent-ng-c1664726108=""
                    className="col-md-12 commission"
                  >
                    <div _ngcontent-ng-c1664726108="" className="tab-container">
                      <ul _ngcontent-ng-c1664726108="" className="nav nav-tabs">
                        <li
                          _ngcontent-ng-c1664726108=""
                          className="nav-item active"
                        >
                          <a
                            _ngcontent-ng-c1664726108=""
                            href="javascript:void(0);"
                            id=""
                            className="nav-link active"
                          >
                            <span _ngcontent-ng-c1664726108="">Fancy</span>
                          </a>
                        </li>
                        <li _ngcontent-ng-c1664726108="" className="nav-item">
                          <a
                            _ngcontent-ng-c1664726108=""
                            href="javascript:void(0);"
                            id=""
                            className="nav-link"
                          >
                            <span _ngcontent-ng-c1664726108="">Matka</span>
                          </a>
                        </li>
                        <li _ngcontent-ng-c1664726108="" className="nav-item">
                          <a
                            _ngcontent-ng-c1664726108=""
                            href="javascript:void(0);"
                            id=""
                            className="nav-link"
                          >
                            <span _ngcontent-ng-c1664726108="">Casino</span>
                          </a>
                        </li>
                        <li _ngcontent-ng-c1664726108="" className="nav-item">
                          <a
                            _ngcontent-ng-c1664726108=""
                            href="javascript:void(0);"
                            id=""
                            className="nav-link"
                          >
                            <span _ngcontent-ng-c1664726108="">Binary</span>
                          </a>
                        </li>
                        <li _ngcontent-ng-c1664726108="" className="nav-item">
                          <a
                            _ngcontent-ng-c1664726108=""
                            href="javascript:void(0);"
                            id=""
                            className="nav-link"
                          >
                            <span _ngcontent-ng-c1664726108="">Sportbook</span>
                          </a>
                        </li>
                        <li _ngcontent-ng-c1664726108="" className="nav-item">
                          <a
                            _ngcontent-ng-c1664726108=""
                            href="javascript:void(0);"
                            id=""
                            className="nav-link"
                          >
                            <span _ngcontent-ng-c1664726108="">Bookmaker</span>
                          </a>
                        </li>
                      </ul>
                      <div _ngcontent-ng-c1664726108="" className="tab-content">
                        <div
                          _ngcontent-ng-c1664726108=""
                          heading="Fancy"
                          className="tab-pane active"
                        />
                        <div
                          _ngcontent-ng-c1664726108=""
                          heading="Matka"
                          className="tab-pane"
                        />
                        <div
                          _ngcontent-ng-c1664726108=""
                          heading="Casino"
                          className="tab-pane"
                        />
                        <div
                          _ngcontent-ng-c1664726108=""
                          heading="Binary"
                          className="tab-pane"
                        />
                        <div
                          _ngcontent-ng-c1664726108=""
                          heading="Sportbook"
                          className="tab-pane"
                        />
                        <div
                          _ngcontent-ng-c1664726108=""
                          heading="Bookmaker"
                          className="tab-pane"
                        />
                        <div
                          _ngcontent-ng-c1664726108=""
                          className="table-responsive table table-striped"
                        >
                          <div
                            id="DataTables_Table_12_wrapper"
                            className="dataTables_wrapper no-footer"
                          >
                            <div
                              className="dataTables_length"
                              id="DataTables_Table_12_length"
                            >
                              <label>
                                Show{" "}
                                <select
                                  name="DataTables_Table_12_length"
                                  aria-controls="DataTables_Table_12"
                                  className=""
                                >
                                  <option value={10}>10</option>
                                  <option value={25}>25</option>
                                  <option value={50}>50</option>
                                  <option value={100}>100</option>
                                </select>{" "}
                                entries
                              </label>
                            </div>
                            <div
                              id="DataTables_Table_12_filter"
                              className="dataTables_filter"
                            >
                              <label>
                                Search:
                                <input
                                  type="search"
                                  className=""
                                  placeholder=""
                                  aria-controls="DataTables_Table_12"
                                />
                              </label>
                            </div>
                            <table
                              _ngcontent-ng-c1664726108=""
                              datatable=""
                              role="table"
                              aria-busy="false"
                              aria-colcount={6}
                              className="table table-bordered table-striped text-center dataTable no-footer"
                              id="DataTables_Table_12"
                              aria-describedby="DataTables_Table_12_info"
                            >
                              <thead
                                _ngcontent-ng-c1664726108=""
                                role="rowgroup"
                              >
                                <tr _ngcontent-ng-c1664726108="" role="row">
                                  <th
                                    _ngcontent-ng-c1664726108=""
                                    className="text-center sorting sorting_desc"
                                    tabIndex={0}
                                    aria-controls="DataTables_Table_12"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-sort="descending"
                                    aria-label="Agent Name: activate to sort column ascending"
                                  >
                                    Agent Name
                                  </th>
                                  <th
                                    _ngcontent-ng-c1664726108=""
                                    className="text-center sorting"
                                    tabIndex={0}
                                    aria-controls="DataTables_Table_12"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Turn Over: activate to sort column ascending"
                                  >
                                    Turn Over
                                  </th>
                                  <th
                                    _ngcontent-ng-c1664726108=""
                                    className="text-center sorting"
                                    tabIndex={0}
                                    aria-controls="DataTables_Table_12"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Commission: activate to sort column ascending"
                                  >
                                    Commission
                                  </th>
                                  <th
                                    _ngcontent-ng-c1664726108=""
                                    className="text-center sorting"
                                    tabIndex={0}
                                    aria-controls="DataTables_Table_12"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Action: activate to sort column ascending"
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="odd">
                                  <td
                                    valign="top"
                                    colSpan={4}
                                    className="dataTables_empty"
                                  >
                                    No matching records found
                                  </td>
                                </tr>
                              </tbody>
                              <tbody></tbody>
                            </table>
                            <div
                              className="dataTables_info"
                              id="DataTables_Table_12_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing 1 to 4 of 4 entries
                            </div>
                            <div
                              className="dataTables_paginate paging_full_numbers"
                              id="DataTables_Table_12_paginate"
                            >
                              <a
                                className="paginate_button first disabled"
                                aria-controls="DataTables_Table_12"
                                aria-disabled="true"
                                role="link"
                                data-dt-idx="first"
                                tabIndex={-1}
                                id="DataTables_Table_12_first"
                              >
                                First
                              </a>
                              <a
                                className="paginate_button previous disabled"
                                aria-controls="DataTables_Table_12"
                                aria-disabled="true"
                                role="link"
                                data-dt-idx="previous"
                                tabIndex={-1}
                                id="DataTables_Table_12_previous"
                              >
                                Previous
                              </a>
                              <span>
                                <a
                                  className="paginate_button current"
                                  aria-controls="DataTables_Table_12"
                                  role="link"
                                  aria-current="page"
                                  data-dt-idx={0}
                                  tabIndex={0}
                                >
                                  1
                                </a>
                              </span>
                              <a
                                className="paginate_button next disabled"
                                aria-controls="DataTables_Table_12"
                                aria-disabled="true"
                                role="link"
                                data-dt-idx="next"
                                tabIndex={-1}
                                id="DataTables_Table_12_next"
                              >
                                Next
                              </a>
                              <a
                                className="paginate_button last disabled"
                                aria-controls="DataTables_Table_12"
                                aria-disabled="true"
                                role="link"
                                data-dt-idx="last"
                                tabIndex={-1}
                                id="DataTables_Table_12_last"
                              >
                                Last
                              </a>
                            </div>
                          </div>
                          {/**/}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        _ngcontent-ng-c1664726108=""
        id="settleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        className="modal fade"
      >
        <div _ngcontent-ng-c1664726108="" className="modal-dialog">
          <div _ngcontent-ng-c1664726108="" className="modal-content">
            <div
              _ngcontent-ng-c1664726108=""
              className="modal-header bg-primary"
            >
              <h5 _ngcontent-ng-c1664726108="" className="modal-title">
                {" "}
                Confirmation -{" "}
              </h5>
              <button
                _ngcontent-ng-c1664726108=""
                data-bs-dismiss="modal"
                type="button"
                className="close"
              >
                <span _ngcontent-ng-c1664726108="" aria-hidden="true">
                  ×
                </span>
              </button>
            </div>
            <div
              _ngcontent-ng-c1664726108=""
              className="modal-body text-center"
            >
              <h5
                _ngcontent-ng-c1664726108=""
                style={{ fontSize: 17, fontWeight: "bold" }}
              >
                Are you sure want to ?
              </h5>
            </div>
            <div _ngcontent-ng-c1664726108="" className="modal-footer">
              <button
                _ngcontent-ng-c1664726108=""
                type="button"
                className="btn btn-primary"
              >
                Yes
              </button>
              <button
                _ngcontent-ng-c1664726108=""
                data-bs-dismiss="modal"
                type="button"
                className="btn btn-default"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </app-commission>
  );
}
