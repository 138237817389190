import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  getActivityLogAPI,
  getPasswordHistoryAPI,
} from "../service/AuthService";
import moment from "moment";
import { useSelector } from "react-redux";

export default function PasswordHistory() {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalPages, setTotalPages] = React.useState(0);
  const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([]);
  const [totalElements, setTotalElements] = React.useState(0);
  const [type, setType] = React.useState("CHANGE_PASSWORD");
  const location = useLocation();
  const [startDate, setStartDate] = React.useState(
    moment().subtract(28, "days").format("YYYY-MM-DDTHH:mm")
  );
  const userName = useSelector((state) => state.account.userData.username);

  const [endDate, setEndDate] = React.useState(
    moment().format("YYYY-MM-DDTHH:mm")
  );
  const getActivityLog = async () => {
    const { response } = await getPasswordHistoryAPI();
    if (response) {
      setData(response);
    }
  };
  useEffect(() => {
    getActivityLog();
  }, [rowsPerPage, pageNumber, startDate, endDate, type]);

  return (
    <app-password-history
      _nghost-ng-c1503613942=""
      className="ng-star-inserted"
    >
      <div _ngcontent-ng-c1503613942="" className="animated fadeIn divider-top">
        <div _ngcontent-ng-c1503613942="" className="row">
          <div _ngcontent-ng-c1503613942="" className="col-sm-12 col-md-12">
            <div
              _ngcontent-ng-c1503613942=""
              className="card card-accent-primary"
            >
              <div
                _ngcontent-ng-c1503613942=""
                className="card-header account-detail-head"
              >
                {" "}
                Password Change History
              </div>
              <div
                _ngcontent-ng-c1503613942=""
                className="card-body account-stat-body"
              >
                <div _ngcontent-ng-c1503613942="" className="row">
                  <div _ngcontent-ng-c1503613942="" className="col-md-12">
                    <div
                      _ngcontent-ng-c1503613942=""
                      className="table-responsive"
                    >
                      <div
                        id="DataTables_Table_13_wrapper"
                        className="dataTables_wrapper no-footer"
                      >
                        <div
                          className="dataTables_length"
                          id="DataTables_Table_13_length"
                        >
                          <label>
                            Show{" "}
                            <select
                              name="DataTables_Table_13_length"
                              aria-controls="DataTables_Table_13"
                              className=""
                              onChange={(e) => {
                                setRowsPerPage(e.target.value);
                              }}
                            >
                              <option value={10}>10</option>
                              <option value={25}>25</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            entries
                          </label>
                        </div>
                        <div
                          id="DataTables_Table_13_filter"
                          className="dataTables_filter"
                        >
                          <label>
                            Search:
                            <input
                              type="search"
                              className=""
                              placeholder=""
                              aria-controls="DataTables_Table_13"
                            />
                          </label>
                        </div>
                        <table
                          _ngcontent-ng-c1503613942=""
                          datatable=""
                          role="table"
                          aria-busy="false"
                          aria-colcount={6}
                          className="table table-bordered table-striped dataTable no-footer"
                          id="DataTables_Table_13"
                          aria-describedby="DataTables_Table_13_info"
                        >
                          <thead _ngcontent-ng-c1503613942="" role="rowgroup">
                            <tr _ngcontent-ng-c1503613942="" role="row">
                              <th
                                _ngcontent-ng-c1503613942=""
                                className="text-center sorting sorting_desc"
                                tabIndex={0}
                                aria-controls="DataTables_Table_13"
                                rowSpan={1}
                                colSpan={1}
                                aria-sort="descending"
                                aria-label="Username: activate to sort column ascending"
                              >
                                Username
                              </th>
                              <th
                                _ngcontent-ng-c1503613942=""
                                className="text-center sorting sorting_desc"
                                tabIndex={0}
                                aria-controls="DataTables_Table_13"
                                rowSpan={1}
                                colSpan={1}
                                aria-sort="descending"
                                aria-label="Username: activate to sort column ascending"
                              >
                                IP
                              </th>
                              <th
                                _ngcontent-ng-c1503613942=""
                                className="text-center sorting_disabled"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Remarks"
                              >
                                Device
                              </th>
                              <th
                                _ngcontent-ng-c1503613942=""
                                className="text-center sorting"
                                tabIndex={0}
                                aria-controls="DataTables_Table_13"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Date &amp; Time: activate to sort column ascending"
                              >
                                Date &amp; Time
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="odd">
                              <td
                                valign="top"
                                colSpan={3}
                                className="dataTables_empty"
                              >
                                No matching records found
                              </td>
                            </tr>
                          </tbody>
                          <tbody>
                            {data.map((item, index) => (
                              <tr
                                _ngcontent-ng-c1503613942=""
                                className="ng-star-inserted"
                              >
                                <td
                                  _ngcontent-ng-c1503613942=""
                                  className="text-center"
                                >
                                  {" "}
                                  {item.userName}
                                </td>
                                <td
                                  _ngcontent-ng-c1503613942=""
                                  className="text-center"
                                >
                                  {" "}
                                  {item.ipAddress}
                                </td>
                                <td
                                  _ngcontent-ng-c1503613942=""
                                  className="text-center"
                                >
                                  {" "}
                                  {item.userAgent}
                                </td>
                                <td
                                  _ngcontent-ng-c1503613942=""
                                  className="text-center"
                                >
                                  {moment(item.createdAt + "Z").format(
                                    "MMM D, YYYY, h:mm:ss A"
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div
                          className="dataTables_info"
                          id="DataTables_Table_13_info"
                          role="status"
                          aria-live="polite"
                        >
                          Showing {pageNumber * rowsPerPage + 1} to{" "}
                          {pageNumber * rowsPerPage + data.length} of{" "}
                          {totalElements} entries
                        </div>
                        <div
                          className="dataTables_paginate paging_full_numbers"
                          id="DataTables_Table_13_paginate"
                        >
                          <a
                            className="paginate_button first disabled"
                            aria-controls="DataTables_Table_13"
                            aria-disabled="true"
                            role="link"
                            data-dt-idx="first"
                            onClick={() => {
                              setPageNumber(0);
                              getActivityLog();
                            }}
                            tabIndex={-1}
                            id="DataTables_Table_13_first"
                          >
                            First
                          </a>
                          <a
                            className="paginate_button previous disabled"
                            aria-controls="DataTables_Table_13"
                            aria-disabled="true"
                            role="link"
                            data-dt-idx="previous"
                            tabIndex={-1}
                            onClick={() => {
                              if (pageNumber > 0) {
                                setPageNumber(pageNumber - 1);
                                getActivityLog();
                              }
                            }}
                            id="DataTables_Table_13_previous"
                          >
                            Previous
                          </a>
                          <span>
                            <a
                              className="paginate_button current"
                              aria-controls="DataTables_Table_13"
                              role="link"
                              aria-current="page"
                              data-dt-idx={0}
                              tabIndex={0}
                            >
                              {pageNumber + 1}
                            </a>
                          </span>
                          <a
                            className="paginate_button next"
                            aria-controls="DataTables_Table_13"
                            role="link"
                            data-dt-idx="next"
                            tabIndex={0}
                            onClick={() => {
                              if (pageNumber < totalPages - 1) {
                                setPageNumber(pageNumber + 1);
                                getActivityLog();
                              }
                            }}
                            id="DataTables_Table_13_next"
                          >
                            Next
                          </a>
                          <a
                            className="paginate_button last"
                            aria-controls="DataTables_Table_13"
                            role="link"
                            data-dt-idx="last"
                            tabIndex={0}
                            onClick={() => {
                              setPageNumber(totalPages - 1);
                              getActivityLog();
                            }}
                            id="DataTables_Table_13_last"
                          >
                            Last
                          </a>
                        </div>
                      </div>
                      {/**/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-password-history>
  );
}
