import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { marketAnalysisAPI } from "../service/AuthService";
import moment from "moment/moment";
import { groupBy } from "lodash";

export default function MyProfitLoss() {
  const [marketAnalysis, setMarketAnalysis] = React.useState({});
  const dispatch = useDispatch();
  const getMarketAnalysis = async () => {
    const { response } = await marketAnalysisAPI();
    let data = response;
    //group by sport key
    let groupedData = data.reduce((r, a) => {
      r[a.sport] = r[a.sport] || [];
      r[a.sport].push(a);
      return r;
    });

    setMarketAnalysis(groupBy(data, "sport"));
    if (!response) {
      dispatch({
        type: "accountData/logOut",
        payload: {},
      });
      window.localStorage.clear();
      window.location.href = "/";
    }
  };
  useEffect(() => {
    getMarketAnalysis();
  }, []);
  return (
    <app-market-analysis _nghost-ng-c1381140131="" className="ng-star-inserted">
      <div _ngcontent-ng-c1381140131="" className="detail-row">
        <div _ngcontent-ng-c1381140131="" className="listing-grid">
          {Object.keys(marketAnalysis).length > 0 &&
            Object.keys(marketAnalysis).map((key) => (
              <div
                _ngcontent-ng-c1381140131=""
                className="table-responsive data-table mt-3 ng-star-inserted"
                style={{ overflow: "hidden" }}
              >
                <div _ngcontent-ng-c1381140131="" className="game-heading">
                  <span
                    _ngcontent-ng-c1381140131=""
                    className="card-header-title"
                  >
                    {key}
                  </span>
                </div>
                <table
                  _ngcontent-ng-c1381140131=""
                  id="example"
                  className="table table-striped table-bordered"
                >
                  <tbody _ngcontent-ng-c1381140131="">
                    {marketAnalysis[key]?.map((item) => (
                      <tr
                        _ngcontent-ng-c1381140131=""
                        className="ng-star-inserted"
                      >
                        <td _ngcontent-ng-c1381140131="">
                          <strong _ngcontent-ng-c1381140131="">
                            <a
                              _ngcontent-ng-c1381140131=""
                              className="marketLink"
                              href={`/market-details/${key}/${item?.eventId}`}
                            >
                              {item?.eventName}
                            </a>
                          </strong>
                        </td>
                        <td _ngcontent-ng-c1381140131="" className="text-end">
                          <span
                            _ngcontent-ng-c1381140131=""
                            style={{ float: "right", fontWeight: "bold" }}
                          >
                            {" "}
                            Total Bets {item?.totalBets}{" "}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
        </div>
      </div>
    </app-market-analysis>
  );
}
